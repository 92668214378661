<template>
  <div class="products-carousel">
    <no-ssr>
      <carousel
        v-bind="sliderConfig"
        @pageChange="setMuted"
        :infinite="true"
        ref="carouselHomepage"
      >
        <slide
          v-for="(product, index) in products"
          :key="index"
        >
          <product-tile
            columns="on"
            class="collection-product"
            :product="product"
            :link-active="false"
            :product-related="true"
            :subscription="parsedSubscription"
            :position="index"
            :list="list"
            @product-slide-click="handleSlideClick(index)"
          />
        </slide>
        <slide class="image-box" />
      </carousel>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { onlineHelper } from '@vue-storefront/core/helpers'
import ProductTile from 'theme/components/core/ProductTile'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import SubscriptionDataMixin from 'theme/mixins/subscriptionDataMixin'

export default {
  name: 'ProductGalleryCarousel',
  mixins: [SubscriptionDataMixin],
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    ProductTile
  },
  props: {
    list: {
      type: String,
      required: false,
      default: 'carousel'
    },
    products: {
      type: null,
      required: true
    },
    isMainPage: {
      type: Boolean,
      default: false
    },
    isBlogPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderConfig: {
        perPageCustom: this.isBlogPage ? [[1, 1.5], [320, 2], [768, 2], [992, 2], [1200, 2], [1400, 3]] : [[1, 1.5], [320, 2], [768, 3], [992, 3], [1200, 4], [1400, 5]],
        centerMode: true,
        touchDrag: true,
        resistanceCoef: 400,
        paginationEnabled: true,
        paginationActiveColor: '#000000',
        paginationColor: '#CFCFCF',
        paginationPosition: 'bottom-overlay',
        navigationEnabled: true,
        navigationNextLabel: '<i class="icon-chevron-right" />',
        navigationPrevLabel: '<i class="icon-chevron-left" />'
      },
      currentPage: 0
    }
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    productLink () {
      var t = {}
      for (var k = 0; k < this.products.length; k++) {
        t[k] = formatProductLink(this.products[k], currentStoreView().storeCode)
      }
      return t
    }
  },
  methods: {
    handleSlideClick (index) {
      this.$router.push(this.productLink[index])
    },
    setMuted (currentPage) {
      if (this.isMainPage) {
        this.$emit('page-changed', { currentPage, perPage: this.$refs.carouselHomepage && this.$refs.carouselHomepage.perPage, type: this.list })
      }
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gray: color(gray);
.products-carousel {
  max-width: 100%;
  .VueCarousel-wrapper {
    padding-bottom: 20px;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .VueCarousel-inner--center {
    justify-content: unset;
  }
  .VueCarousel-pagination--bottom-overlay {
    bottom: -5px !important;
    @media (max-width: 767px) {
      bottom: -10px !important;
    }
  }
  .VueCarousel-slide {
    overflow: auto;
  }
  .VueCarousel-pagination--bottom-overlay {
    bottom: -15px !important;
    @media (max-width: 767px) {
      bottom: -35px !important;
    }
  }
  .VueCarousel-navigation-prev {
    left: initial !important;
    right: 53px !important;
    @media (max-width: 767px) {
      left: initial !important;
      right: 53px !important;
    }
  }
  .VueCarousel-navigation-next {
    right: 0 !important;
    @media (max-width: 767px) {
      right: 0 !important;
    }
  }
  .VueCarousel-navigation-button {
    top: -57px !important;
    background-color: transparent !important;
    width: 45px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    color: gray !important;
    z-index: 1 !important;
    @media (max-width: 767px) {
      background-color: white !important;
      top: -57px !important;
      width: 45px !important;
      height: 45px !important;
    }
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    .VueCarousel-dot {
      margin: 0 !important;
    }
  }
  // .VueCarousel-dot-container {
  //   .VueCarousel-dot.VueCarousel-dot--active {
  //     box-shadow: 0px 0px 1px 0.5px;
  //   }
  // }
  .VueCarousel-navigation--disabled {
    display: none !important;
  }
  .VueCarousel-navigation-prev {
    transform: none !important;
    i {
      font-size: 45px;
      @media (max-width: 767px) {
        font-size: 35px;
      }
    }
  }
  .VueCarousel-navigation-next {
    transform: none !important;
    i {
      font-size: 45px;
      padding-left: 4px;
      @media (max-width: 767px) {
        font-size: 35px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.product {
  &.collection-product {
    padding-right: 0;
    width: auto;
    margin: 20px !important;
    @media (max-width: 767px) {
      margin: 0 !important;
      max-width: 190px;
    }
  }
}
</style>
